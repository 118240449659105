import TextareaAutosize from "react-textarea-autosize";
import classNames from "classnames";

const TextArea = ({
  id,
  placeholder,
  onChange,
  value,
  readonly,
  inputRef,
  textfieldLike = false,
}) => {
  return (
    <div className="my-2">
      <TextareaAutosize
        minRows={2}
        ref={inputRef}
        readOnly={readonly}
        className={classNames(
          "form-input block w-full py-2.5 px-4 rounded-lg overflow-hidden text-base bg-background border border-foreground/20 hover:border-foreground/7 hover:bg-foreground/7 focus:outline-none focus:shadow-none focus:bg-background  focus:border-accent  focus:ring-0 transition duration-150 ease-in-out placeholder-foreground/50 print:placeholder-transparent",
          textfieldLike ? `resize-none rounded-xl` : `hover:bg-grey-950`
        )}
        onChange={onChange}
        name={id}
        data-name={id}
        placeholder={placeholder}
        id={id}
        defaultValue={value}
      />
    </div>
  );
};

export default TextArea;
