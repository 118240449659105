import React, { FC } from "react";
import classNames from "classnames";

export interface ScaleFieldProps {
  value?: number | null;
  onChange: (value: number) => void;
  readonly?: boolean;
  scaleBoundaries?: {
    min: number;
    max: number;
  };
}

const ScaleField: FC<ScaleFieldProps> = ({
  value,
  onChange,
  readonly,
  scaleBoundaries,
}) => {
  const { min: SCALE_START = 1, max: SCALE_COUNT = 5 } = scaleBoundaries ?? {};

  const SCALE_ITEMS = [...Array(SCALE_COUNT).keys()].map(
    (x) => SCALE_START + x
  );

  return (
    <div className="bg-background text-foreground flex divide-x divide-foreground/20 border border-1 border-foreground/20 rounded-lg h-10 align-center overflow-hidden">
      {SCALE_ITEMS.map((num) => (
        <button
          disabled={readonly}
          type="button"
          key={num}
          onClick={() => onChange(num)}
          className={classNames(
            "flex-1 text-center leading-10 transition focus:outline-none",
            value === num && "bg-accent text-button-text"
          )}
        >
          {num}
        </button>
      ))}
    </div>
  );
};

export default ScaleField;
