import { FC } from "react";
import { Controller } from "react-hook-form";

import { ErrorMessage } from "@components/ErrorMessage";

import { FieldWrapperProps } from "../types";

const FieldWrapper: FC<FieldWrapperProps> = ({
  name,
  index,
  Component,
  item,
  defaultValue,
  validator,
  control,
  errors,
  readonly,
  coachId,
  signatureTemplateId,
  contact,
  internal = false,
  currencies,
}) => {
  return (
    <Controller
      name={`${name}[${index}]`}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => {
        const errorMessage = errors?.[name]?.[index]?.message;
        return (
          <div className="mb-8 relative w-full">
            <p className="font-medium whitespace-pre-line mb-2 text-foreground">
              {item.question}
              {item.required ? ` *` : ""}
            </p>
            <Component
              readonly={readonly}
              internal={internal}
              value={value}
              onChange={onChange}
              item={item}
              coachId={coachId}
              signatureTemplateId={signatureTemplateId}
              contact={contact}
              currencies={currencies}
              scaleBoundaries={
                item.min && item.max
                  ? { min: item.min, max: item.max }
                  : undefined
              }
            />
            {errorMessage && (
              <ErrorMessage className="mt-3">{errorMessage}</ErrorMessage>
            )}
          </div>
        );
      }}
      control={control}
      rules={{
        validate: validator,
        required: item.required && "This answer is required.",
      }}
    />
  );
};

export default FieldWrapper;
