import { FC, useState } from "react";
import { SingleDatePicker } from "react-dates";
import moment from "moment";

interface DateFieldProps {
  value?: Date;
  onChange: (value?: Date) => void;
  readonly?: boolean;
}

const DateField: FC<DateFieldProps> = ({
  value,
  onChange,
  readonly = false,
}) => {
  const [focused, setFocused] = useState(false);
  return (
    <div id="public-date-picker-wrapper">
      <SingleDatePicker
        id="public-date-picker"
        date={value ? moment(value) : null}
        onDateChange={(date) => onChange(date?.toDate())}
        onFocusChange={({ focused }) => setFocused(focused)}
        displayFormat="MMM DD YYYY"
        focused={focused}
        readOnly={readonly}
        noBorder={true}
        numberOfMonths={1}
        transitionDuration={0}
        isOutsideRange={() => false}
        showClearDate
      />
    </div>
  );
};
export default DateField;
