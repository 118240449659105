import React, { FC, useEffect, useState } from "react";
import { DropboxSignSignaturePostPayload } from "api-services/definitions/dropboxsign";
import axios from "axios";

import analytics from "@lib/analytics";

import Spinner from "@components/LoadingSpinner";
import Modal from "@components/Modals/Modal";

export type OnSignedType = (data: {
  signatureId: string;
  signatureRequestId: string;
}) => void;

interface DropboxSignSignatureModalProps {
  signatureTemplateId: string;
  onSigned: OnSignedType;
  onClose: () => void;
  show: boolean;
  coachId: string;
  signer: DropboxSignSignaturePostPayload["signer"];
}

const DropboxSignSignatureModal: FC<DropboxSignSignatureModalProps> = ({
  signatureTemplateId,
  show,
  onSigned,
  onClose,
  coachId,
  signer,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    import("@lib/dropboxsign-embedded").then(async (lib) => {
      const DropboxSignClientId = await lib.DropboxSignClientId(coachId);
      const DropboxSignEmbedded = await lib.DropboxSignEmbedded(coachId);

      const body = {
        clientId: DropboxSignClientId,
        templateId: signatureTemplateId,
        userId: coachId,
        signer,
      };
      const response = await axios.post(
        `/api/v1/users/${coachId}/dropboxsign/signature`,
        body
      );

      analytics.track("client_opened_digital_signature");
      DropboxSignEmbedded.open(response.data.signUrl, {
        container: document.getElementById("docusign-wrapper") as HTMLElement,
      });

      DropboxSignEmbedded.on("sign", (data) => {
        analytics.track("client_sign_digital_signature");
        onSigned({
          ...data,
          signatureRequestId: response.data.signatureRequestId,
        });
      });

      DropboxSignEmbedded.on("message", (data) => {
        // We use unknown here because HelloSign.messages types
        // are currently broken and do not validate.
        // e.g data.type == HelloSign.messages.USER_CANCEL_REQUEST
        if ((data.type as unknown) == "hellosign:userCancelRequest") {
          analytics.track("client_sign_digital_signature_cancel");
          onClose();
        }
      });

      DropboxSignEmbedded.on("ready", () => {
        setIsLoading(false);
      });

      return () => {
        DropboxSignEmbedded.off("ready");
        DropboxSignEmbedded.off("sign");
        DropboxSignEmbedded.off("message");
      };
    });
  }, []);

  return (
    <Modal show={show} size="full" className="max-w-6xl overflow-hidden">
      <div id="docusign-wrapper" className="flex flex-col h-full relative ">
        {isLoading && (
          <div className="flex items-center justify-center absolute inset-0 bg-white">
            <Spinner />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default DropboxSignSignatureModal;
