import { FC, useMemo } from "react";
import { orderBy } from "lodash";

interface CurrencyFieldProps {
  value?: string;
  onChange: (value?: string) => void;
  readonly?: boolean;
  currencies?: { code: string }[];
}

const CurrencyField: FC<CurrencyFieldProps> = ({
  value,
  onChange,
  readonly = false,
  currencies = [],
}) => {
  const topCurrencies = ["USD", "CAD", "EUR"];

  const currencyOptions = useMemo(() => {
    const options = currencies
      .map(({ code }) => ({ value: code, label: code }))
      .filter(({ value }) => !topCurrencies.includes(value));

    const topOrderOptions = topCurrencies.map((code) => ({
      value: code,
      label: code,
    }));

    const orderedOptions = orderBy(options, "label", "asc");

    return [...topOrderOptions, ...orderedOptions];
  }, [currencies]);

  return (
    <select
      className="py-2.5 px-4 w-full border rounded-xl text-foreground bg-background border-foreground/20 focus:ring-0 focus:outline-none focus:shadow-none transition duration-150 ease-in-out focus:bg-background focus:border-accent hover:bg-foreground/7 hover:border-transparent placeholder-foreground/50"
      name="currencies"
      value={value}
      onChange={(event) => onChange(event.target.value)}
      disabled={readonly}
    >
      <option value="">Select currency</option>
      {currencyOptions.map(({ value, label }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </select>
  );
};

export default CurrencyField;
