import React, { FC, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { File as FileType } from "types/db/file";

import { useAuth } from "@contexts/auth";

import {
  InlineUpload,
  InlineUploadProps,
  UploadFileInfo,
} from "@components/UploadModal";

export type OnFileChangeType = (data: FileType | null) => void;

interface LegalInputFieldProps {
  onChange?: OnFileChangeType;
  onClickDelete: () => void;
  required?: boolean;
}

interface FileInputProps {
  value: FileType;
  onChange: OnFileChangeType;
  onClickDelete?: () => void;
  uid: string;
  readonly?: boolean;
  uploadPath?: string;
  inlineUploadProps?: Partial<InlineUploadProps>;
  customButton?: React.ReactNode;
}

const LegalInputField: FC<LegalInputFieldProps> = ({
  onChange,
  onClickDelete,
  required = false,
}) => {
  const { control } = useFormContext();
  const { uid } = useAuth();

  const handleChange = (callback: OnFileChangeType) => (data: FileType) => {
    callback && callback(data);
    onChange && onChange(data);
  };

  return (
    <Controller
      control={control}
      name="file"
      rules={{ required }}
      defaultValue={null}
      render={({ field }) => (
        <FileInput
          value={field.value}
          onChange={handleChange(field.onChange)}
          onClickDelete={onClickDelete}
          uid={uid}
        />
      )}
    />
  );
};

export const FileInput: FC<FileInputProps> = ({
  value,
  onChange,
  uid,
  readonly = false,
  onClickDelete,
  uploadPath,
  inlineUploadProps = {},
  customButton,
}) => {
  const [files, setFiles] = useState<UploadFileInfo[]>(
    value ? [{ uploadedFile: value, uploading: null, itemId: "" }] : []
  );

  useEffect(() => {
    if (!files.length) onChange(null);
    else onChange(files[0].uploadedFile || null);
  }, [files]);

  if (!uid) return null;

  return (
    <InlineUpload
      files={files}
      setFiles={setFiles}
      readonly={readonly}
      uploadPath={uploadPath || `/forms/${uid}/file/`}
      onClickDelete={onClickDelete}
      vertical
      {...inlineUploadProps}
      customButton={customButton}
    />
  );
};

export default LegalInputField;
