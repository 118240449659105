import React, { FC } from "react";
import { useRouter } from "next/router";

import { useAuth } from "@contexts/auth";

import { FileInput } from "@components/Form/FileUploadField";

import { FileFieldProps } from "../types";

const FileField: FC<FileFieldProps> = ({
  value,
  onChange,
  readonly,
  internal,
}) => {
  const { oid } = useAuth();
  const router = useRouter();
  const { contactId, userId } = router.query;

  return (
    <FileInput
      readonly={readonly}
      value={value}
      onChange={onChange}
      uid={internal ? oid : contactId || userId}
    />
  );
};

export default FileField;
