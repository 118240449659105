import React, { FC } from "react";

import ExtendedFilePreview from "@components/ExtendedFilePreview";
import { Checkbox } from "@components/Form/Checkbox";

import { LegalFieldProps } from "../types";

const LegalField: FC<LegalFieldProps> = ({
  item,
  value,
  onChange,
  readonly = false,
}) => {
  const renderFilePrview = item?.file && (
    <div
      className="mb-6 pointer"
      onClick={() => window.open(item.file.url, "_target")}
    >
      <ExtendedFilePreview className="pt-5" file={item.file} />
    </div>
  );

  return (
    <>
      {renderFilePrview}
      <label className="flex">
        <Checkbox
          disabled={readonly}
          checked={value}
          value={value}
          defaultChecked={item.answer || false}
          onChange={() => onChange(!value)}
        />
        <div className="ml-3 text-foreground">
          I have read and agree to the terms above
        </div>
      </label>
    </>
  );
};
export default LegalField;
