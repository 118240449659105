export type ChoiceFormItemType = {
  custom: {
    options?:
      | {
          items?: string[];
        }
      | boolean;
  };
  options: { items: { value: string }[] };
};

export const getChoiceFormItem = (formItem: ChoiceFormItemType) => {
  const isCustom = !!formItem.custom;
  const customOptions =
    isCustom &&
    formItem.custom.options?.items?.map((item: string) => ({
      value: item,
    }));
  return isCustom ? { ...formItem, options: customOptions } : formItem;
};
