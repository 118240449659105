import React, { FC, MouseEvent, useState } from "react";
import classNames from "classnames";

import { displayNameFromContact } from "@lib/contacts";

import { Button } from "@components/Button";
import ExtendedFilePreview from "@components/ExtendedFilePreview";
import {
  DropboxSignSignatureModal,
  OnSignedType,
  SignedBox,
} from "@components/Form/DigitalSignature";
import LinkIcon from "@components/Icons/LinkIcon";
import FloatingTooltipWrapper from "@components/Tooltips/FloatingTooltipWrapper";

import { DigitalSignatureFieldProps } from "../types";

const DigitalSignatureField: FC<DigitalSignatureFieldProps> = ({
  item,
  value,
  onChange,
  coachId,
  contact,
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const contactName = displayNameFromContact(contact);
  const contactEmail = contact?.email;
  const isSignerValid = !!contact?.firstName && !!contactName && !!contactEmail;
  const canOpenModal = !value && isSignerValid;

  const onFilePreviewClick = (e?: MouseEvent) => {
    if (canOpenModal) {
      handleToggleModal(e);
    }
  };

  const handleToggleModal = (e?: MouseEvent) => {
    e?.preventDefault();
    setShowModal(!showModal);
  };

  const handleChange: OnSignedType = (data) => {
    handleToggleModal();
    onChange(data);
  };

  const renderFilePreview = item?.file && (
    <div className="flex justify-center w-full">
      <div
        className="mb-6 pointer relative max-w-[348px] "
        onClick={onFilePreviewClick}
      >
        <FloatingTooltipWrapper
          text="Complete the rest of the form before signing the document."
          enabled={!canOpenModal && !value}
        >
          <ExtendedFilePreview
            file={item.file}
            fullWidth={true}
            showFileName={false}
            filePreviewClassName={classNames(
              "border-foreground/20 rounded-b-none",
              !canOpenModal && "hover:opacity-100 cursor-default"
            )}
            viewOnly={true}
          />
          <SignedBox variant={value ? "signed" : "unsigned"}>
            <div className="p-1">
              {value
                ? "Signed"
                : item.required
                ? "Signature required"
                : "Not signed"}
            </div>

            {canOpenModal && (
              <Button
                className="ml-auto"
                onClick={handleToggleModal}
                variant="primary-theme"
                smaller
              >
                Sign
                <LinkIcon className="ml-1 transform rotate-90" />
              </Button>
            )}
          </SignedBox>
        </FloatingTooltipWrapper>
      </div>
    </div>
  );

  const renderModal = showModal && isSignerValid && (
    <DropboxSignSignatureModal
      show={showModal}
      signatureTemplateId={item.signatureTemplate.serviceData.templateId}
      onSigned={handleChange}
      onClose={handleToggleModal}
      coachId={coachId}
      signer={{
        name: contactName,
        emailAddress: contactEmail,
      }}
    />
  );

  return (
    <>
      {renderFilePreview}
      {renderModal}
    </>
  );
};
export default DigitalSignatureField;
