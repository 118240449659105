import React, { FC, useEffect, useState } from "react";
import axios from "axios";
import { File as FileType } from "types/db/file";
import { DigitalSignatureTemplate } from "types/digitalsignature";

import { useAuth } from "@contexts/auth";
import analytics from "@lib/analytics";

import LegalInputField from "@components/Form/FileUploadField";
import Label from "@components/Form/Label";
import CheckIcon from "@components/Icons/CheckIcon";
import InfoIcon from "@components/Icons/InfoIcon";
import Spinner from "@components/LoadingSpinner";
import QuestionHeader from "@components/QuestionsBuilder/QuestionItem/QuestionHeader";

interface DigitalSignatureQuestionProps {
  onSignatureTemplate: (data: DigitalSignatureTemplate) => void;
  question: any;
}

const DigitalSignatureQuestion: FC<DigitalSignatureQuestionProps> = ({
  onSignatureTemplate,
  question,
}) => {
  const { uid } = useAuth();
  const [isLoadingHelloSign, setIsLoadingHelloSign] = useState<boolean>(false);
  const [showHelloSignSucess, setShowHelloSignSucess] =
    useState<boolean>(false);
  const [deleteDigitalSignatureFile, setDeleteDigitalSignatureFile] =
    useState<boolean>(false);
  const [digitalSignatureFileUploaded, setDigitalSignatureFileUploaded] =
    useState<FileType>();
  const shouldEdit =
    !!question?.signatureTemplate?.serviceData?.templateId &&
    !deleteDigitalSignatureFile;

  const handleDigitalFileUploaded = (data: any) => {
    if (data) {
      setDigitalSignatureFileUploaded(data);
    }
  };

  const handleDeleteDigitalDignatureFile = () => {
    setDeleteDigitalSignatureFile(true);
  };

  const handleOpenHelloSign = async (file: FileType) => {
    try {
      setDeleteDigitalSignatureFile(false);
      import("@lib/dropboxsign-embedded").then(async (lib) => {
        const DropboxSignClientId = await lib.DropboxSignClientId(uid);
        const DropboxSignEmbedded = await lib.DropboxSignEmbedded(uid);

        const body = {
          clientId: DropboxSignClientId,
          file,
        };

        let editUrl;
        const baseUrl = `/api/v1/users/${uid}/dropboxsign/template`;
        if (shouldEdit) {
          const { data } = await axios.patch(baseUrl, {
            templateId: question.signatureTemplate.serviceData.templateId,
          });
          editUrl = data.embedded.editUrl;
        } else {
          const { data } = await axios.post(baseUrl, body);
          onSignatureTemplate(data);
          onSignatureTemplate(data);
          editUrl = data.serviceData.editUrl;
        }

        DropboxSignEmbedded.open(editUrl, {
          // @TODO: the modal to build the template is very big, for a better UX,
          //        the functional version will use the default modal
          // container: document.getElementById(
          // "dropboxsign_container"
          // ) as HTMLElement,
        });

        DropboxSignEmbedded.on("ready", () => {
          setIsLoadingHelloSign(false);
        });

        DropboxSignEmbedded.on("createTemplate", () => {
          const action = shouldEdit ? "edited" : "created";
          analytics.track(`coach_${action}_dropboxsign_template`);
          setShowHelloSignSucess(true);
        });

        DropboxSignEmbedded.on("error", (err) => {
          analytics.track("coach_dropboxsign_error_to_load");
          console.log("docusign client error >>>", err);
        });
      });
    } catch (e) {
      analytics.track("coach_dropboxsign_template_error");
      console.log("error >>>", e);
    }
  };

  useEffect(() => {
    if (digitalSignatureFileUploaded) {
      handleOpenHelloSign(digitalSignatureFileUploaded);
      setIsLoadingHelloSign(true);
    }
  }, [digitalSignatureFileUploaded]);

  return (
    <>
      <QuestionHeader
        defaultRequired
        placeholder="Add the description you want to display on top of your contract."
        label="Contract description*"
      >
        <Label className="mb-2">Add a document*</Label>
        <LegalInputField
          onChange={handleDigitalFileUploaded}
          onClickDelete={handleDeleteDigitalDignatureFile}
          required
        />
        <div className="bg-grey-950 rounded-lg p-4 mt-6">
          <p className="text-black-ink font-medium text-sm flex items-center mb-2">
            <InfoIcon className="mr-2" />
            Easy and intuitive signature
          </p>
          <p className="text-sm text-grey-500">
            Once you add your file, you can set dynamic fields, such as
            signatures from your clients.
          </p>
        </div>
      </QuestionHeader>

      <div id="dropboxsign_container" className="flex flex-col relative">
        {isLoadingHelloSign && (
          <div className="flex items-center justify-center py-10 bg-white">
            <Spinner />
          </div>
        )}
        {showHelloSignSucess && (
          <p className="flex text-green-300 my-6">
            <span className="flex rounded-full bg-green-600 text-green-300 w-6 h-6 items-center justify-center mr-2">
              <CheckIcon className="w-4 h-4" />
            </span>
            Template {shouldEdit ? "edited" : "created"}
          </p>
        )}
      </div>
    </>
  );
};

export default DigitalSignatureQuestion;
