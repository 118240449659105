import React, { FC, ReactNode } from "react";
import { Controller, useFormContext } from "react-hook-form";
import TextareaAutosize from "react-textarea-autosize";
import classNames from "classnames";

import Toggle from "@components/Form/Toggle";

interface QuestionHeaderProps {
  children?: ReactNode;
  name?: string;
  label?: string;
  placeholder?: string;
  defaultRequired?: boolean;
  hasRequiredDivider?: boolean;
  requiredLast?: boolean;
  hasRequiredToggle?: boolean;
  minRows?: number;
}

const QuestionHeader: FC<QuestionHeaderProps> = ({
  children,
  name = "question",
  label = "Question",
  placeholder = "Type question here...",
  defaultRequired = false,
  hasRequiredDivider = false,
  requiredLast = true,
  hasRequiredToggle = true,
  minRows = 2,
}) => {
  const { register, control } = useFormContext();

  return (
    <div
      className={classNames(
        "flex flex-col min-h-0",
        hasRequiredToggle && "space-y-4"
      )}
    >
      <div>
        <label
          className="block font-normal text-sm leading-5 text-grey-500 mb-2"
          htmlFor={"question"}
        >
          {label}
        </label>
        <TextareaAutosize
          autoFocus={true}
          minRows={minRows}
          {...register(name, { required: true })}
          name={name}
          placeholder={placeholder}
          className="resize-none overflow-hidden h-11 form-input hover:bg-grey-950 hover:border-grey-950 block w-full p-3 bg-transparent border-grey-900 focus:shadow-none focus:border-yellow-700 focus:ring-transparent transition duration-150 ease-in-out"
        />
      </div>
      {!requiredLast && children}
      {!defaultRequired && hasRequiredToggle && (
        <div className="flex mt-6 pr-1 pb-1">
          <div className="mr-auto">Required</div>
          <Controller
            control={control}
            defaultValue={false}
            name="required"
            render={({ field: { value, onChange } }) => {
              return <Toggle value={value} onChange={onChange} />;
            }}
          />
        </div>
      )}
      {hasRequiredDivider && <div className="-mx-6 h-px bg-grey-900" />}
      <div>{requiredLast && children}</div>
    </div>
  );
};

export default QuestionHeader;
