import { ClientType } from "@lib/data/schemas/client";
import { ContactFormInfoType } from "@lib/shared-types";

export const contactForm = (
  contact: ClientType | ContactFormInfoType | undefined
): ContactFormInfoType => {
  return {
    firstName: contact?.firstName || "",
    lastName: contact?.lastName || "",
    email: contact?.email || "",
    answers: [],
  };
};

export const getClientId = (clientId: string | string[]): string =>
  Array.isArray(clientId) ? clientId[0] : (clientId as string);
