import React, { FC } from "react";
import classNames from "classnames";
import { File as FileType } from "types/db/file";

import FileIcon from "@components/Icons/FileIcon";
import LinkIcon from "@components/Icons/LinkIcon";

import { Button } from "./Button";
import FilePreview from "./FilePreview";

interface ExtendedFilePreviewProps {
  file: FileType;
  className?: string;
  fullWidth?: boolean;
  showFileName?: boolean;
  filePreviewClassName?: string;
  viewOnly?: boolean;
}

const ExtendedFilePreview: FC<ExtendedFilePreviewProps> = ({
  file,
  className,
  fullWidth = false,
  showFileName = true,
  filePreviewClassName,
  viewOnly = false,
}) => {
  return (
    <div className={classNames("flex flex-col items-center", className)}>
      <div className={fullWidth ? "w-full" : "w-[300px]"}>
        <a
          className="text-center z-1 group"
          href={file.url}
          target="_blank"
          rel="noreferrer"
          onClick={viewOnly ? (e) => e.preventDefault() : undefined}
        >
          <div className="relative">
            <FilePreview
              imgClassName={classNames(
                "rounded-lg border border-foreground/20 border-solid",
                filePreviewClassName
              )}
              className="overflow-hidden"
              size={300}
              url={file.url}
              fallback={<FileIcon />}
            />
            {!viewOnly && (
              <Button
                variant="primary-theme"
                className="z-2 absolute bottom-3 right-3 invisible group-hover:visible"
                component="span"
              >
                <span className="flex items-baseline">
                  Open file <LinkIcon className="ml-2 transform -scale-x-1" />
                </span>
              </Button>
            )}
          </div>

          {showFileName && (
            <p className="text-foreground/75 text-xl mt-2 mb-6 text-center">
              {file.fileName}
            </p>
          )}
        </a>
        <div></div>
      </div>
    </div>
  );
};

export default ExtendedFilePreview;
