import React from "react";
import classNames from "classnames";
import { find, findIndex } from "lodash";

import { getChoiceFormItem } from "./utils";

export const Checkbox = ({
  readonly = false,
  onChange = undefined,
  checked = false,
  className,
  name,
  value,
}) => (
  <div className={classNames("flex items-center", className)}>
    <input
      disabled={readonly}
      type="checkbox"
      onChange={onChange}
      checked={checked}
      name={name}
      value={value}
      className={classNames(
        "invisible absolute h-8 w-8 cursor-pointer text-foreground"
      )}
    />
    <div
      className={classNames(
        "bg-background border-2 border-foreground/50 rounded-sm w-5 h-5 cursor-pointer flex flex-shrink-0 justify-center items-center mr-2 focus:border-accent",
        checked && "border-accent/100"
      )}
    >
      <svg
        className={classNames(
          checked ? "block" : "hidden",
          "fill-current w-3 h-3 text-accent cursor-pointer"
        )}
        version="1.1"
        viewBox="0 0 17 12"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="none" fillRule="evenodd">
          <g
            transform="translate(-9 -11)"
            className="fill-current"
            fillRule="nonzero"
          >
            <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
          </g>
        </g>
      </svg>
    </div>
  </div>
);

function MultiChoiceField({ item: formItem, value, onChange, readonly }) {
  const item = getChoiceFormItem(formItem);

  function onCheckboxChange(option) {
    const nonEmptyValue = value || [];
    const optionIndex = findIndex(nonEmptyValue, option);
    switch (optionIndex) {
      case -1:
        onChange([...nonEmptyValue, option]);
        break;
      default:
        onChange([
          ...nonEmptyValue.filter((filteredOption) => {
            return filteredOption.value !== option.value;
          }),
        ]);
        break;
    }
  }

  return (
    <div className="bg-background text-foreground rounded-md">
      {item.options?.map((option, optionIdx) => {
        const checked = find(value, option) || false;
        return (
          <label
            key={optionIdx}
            className={classNames(
              optionIdx === 0 ? "rounded-tl-md rounded-tr-md" : "",
              optionIdx === item.options.length - 1
                ? "rounded-bl-md rounded-br-md"
                : "",
              "w-full relative mb-2 flex cursor-pointer focus:outline-none"
            )}
          >
            <Checkbox
              readonly={readonly}
              checked={checked}
              onChange={() => onCheckboxChange(option)}
              className="mr-3"
            />
            <span className="text-base">{option.value}</span>
          </label>
        );
      })}
    </div>
  );
}

export default MultiChoiceField;
