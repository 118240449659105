import React, { FC, ReactNode } from "react";
import classNames from "classnames";

import CheckIcon from "@components/Icons/CheckIcon";
import PenIcon from "@components/Icons/PenIcon";

type VariantTypes = "signed" | "unsigned";

type StypeTypes = {
  [key in VariantTypes]?: string;
};

interface SignedBoxProps {
  variant: VariantTypes;
  className?: string;
  children: ReactNode;
}

const styles: StypeTypes = {
  signed: "text-accent",
  unsigned: "text-foreground/50",
};

const CheckRoundIcon: FC<{ className: string }> = ({ className }) => (
  <span
    className={classNames(
      "inline-block rounded-full bg-accent text-background",
      className
    )}
  >
    <CheckIcon />
  </span>
);

const SignedBox: FC<SignedBoxProps> = ({ variant, className, children }) => {
  const style = styles[variant] || "";
  const Icon = variant === "signed" ? CheckRoundIcon : PenIcon;
  return (
    <div
      className={classNames(
        "flex items-center p-3 rounded-b-lg border border-foreground/20 border-t-0",
        style,
        className
      )}
    >
      <Icon className="mr-2" />
      {children}
    </div>
  );
};

export default SignedBox;
