import { FC } from "react";

import TextArea from "@components/Client/TextArea";

export interface TextboxFieldProps {
  value?: string;
  onChange: (val: string) => void;
  readonly?: boolean;
}

const TextboxField: FC<TextboxFieldProps> = ({ value, onChange, readonly }) => {
  return (
    <TextArea
      onChange={(e) => onChange(e.target.value)}
      value={value}
      readonly={readonly}
      placeholder="Not answered yet"
      textfieldLike
    />
  );
};

export default TextboxField;
