import React, { FC } from "react";

import CurrencyField from "./Fields/CurrencyField";
import DateField from "./Fields/DateField";
import DigitalSignatureField from "./Fields/DigitalSignatureField";
import FieldWrapper from "./Fields/FieldWrapper";
import FileField from "./Fields/FileField";
import LegalField from "./Fields/LegalField";
import MultiChoiceField from "./Fields/MultiChoiceField";
import NumberField from "./Fields/NumberField";
import ScaleField from "./Fields/ScaleField";
import SingleChoiceField from "./Fields/SingleChoiceField";
import TextboxField from "./Fields/TextboxField";
import {
  IntakeFormFieldItemsTypes,
  IntakeFormFieldsProps,
  IntakeFormItemTypes,
} from "./types";

const FIELDS: IntakeFormFieldItemsTypes = {
  "single-choice": { default: null, component: SingleChoiceField },
  "multi-choice": { default: null, component: MultiChoiceField },
  legal: {
    default: false,
    component: LegalField,
    validator: (val) => (val !== true ? "This answer is required." : undefined),
  },
  "digital-signature": {
    default: "",
    component: DigitalSignatureField,
    validator: (val) => (!val ? "This answer is required." : undefined),
  },
  file: { default: null, component: FileField },
  scale: { default: null, component: ScaleField },
  text: { default: "", component: TextboxField },
  textbox: { default: "", component: TextboxField },
  custom: { default: "", component: TextboxField },
  number: { default: "", component: NumberField },
  date: { default: null, component: DateField },
  currency: { default: "", component: CurrencyField },
};

export const IntakeFormFields: FC<IntakeFormFieldsProps> = ({
  form,
  readonly = false,
  control,
  errors,
  coachId,
  contact,
  internal = false,
  currencies,
}) => {
  return form.items.map((item: any, index: number) => {
    return item.questionType === "statement" ? (
      <div className="mb-6 text-foreground whitespace-pre-wrap">
        {item.question}
      </div>
    ) : (
      <FieldWrapper
        key={`form-field-item-${index}`}
        control={control}
        errors={errors}
        Component={FIELDS[item.questionType as IntakeFormItemTypes].component}
        item={item}
        defaultValue={
          form.items[index].answer ||
          FIELDS[item.questionType as IntakeFormItemTypes].default
        }
        name="answers"
        index={index}
        readonly={readonly}
        validator={FIELDS[item.questionType as IntakeFormItemTypes].validator}
        coachId={coachId || form.coachUserId}
        contact={contact}
        internal={internal}
        currencies={currencies}
      />
    );
  });
};
