import { FC } from "react";

import TextFieldForm from "@components/Form/TextFieldForm";

export interface NumberFieldProps {
  value?: number;
  onChange: (value: number) => void;
  readonly?: boolean;
}

const NumberField: FC<NumberFieldProps> = ({ value, onChange, readonly }) => {
  return (
    <TextFieldForm
      type="number"
      onInputChange={(e) => onChange(Number(e.target.value))}
      inputClassName="rounded-xl py-2.5 bg-background border !border-foreground/20 hover:!border-foreground/7 hover:!bg-foreground/7 focus:outline-none focus:shadow-none focus:!bg-background focus:!border-accent focus:ring-0"
      readOnly={readonly}
      defaultValue={value}
      name="number"
      min="0"
    />
  );
};

export default NumberField;
